import { StaticImage } from 'gatsby-plugin-image';
import { RiCalendar2Fill, RiTimeLine } from 'react-icons/ri';
import { HeadProps } from 'gatsby';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
  Speakers,
  Schedule,
} from '@augustine-institute/lib';
import SEO from '../components/Seo';
import Bonus from '../components/2021/Bonus';
import Form from '../components/2021/Form';
import Sessions from '../components/Sessions';
import { EVENT_START, EVENT_END } from '../utils/2021/config';
import { speakers } from '../data/2021/speakers';
import { schedule } from '../data/2021/schedule';
import { breakoutSessions } from '../data/2021/breakoutSessions';
import PageHeader from '../components/PageHeader';

// * This assumes that event start & end dates are the same day.

const dateString = EVENT_START.toLocaleDateString('en-US', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

// Get the time string - range between EVENT_START and EVENT_END
const timeString = `${EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
})} - ${EVENT_END.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
})}`;

const startTimeString = EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
});

export default function HomePage() {
  const eventStart = EVENT_START;
  const today = new Date();
  const msToEvent = eventStart.valueOf() - today.valueOf();
  const daysToEvent = Math.floor(msToEvent / (24 * 60 * 60 * 1000));

  const daysToEventString = `${daysToEvent} ${
    daysToEvent > 1 ? 'days' : 'day'
  }`;

  return (
    <main className="page-2021">
      <section className="container page-m-t section-m-b">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-7">
            <PageHeader
              title="Theology: The Renewal of the Mind"
              subtitle="A Virtual Summit for Prospective Students"
              date={dateString}
              time={timeString}
            />
            <div className="event-details">
              <ul>
                <li>
                  <RiCalendar2Fill title="date" />
                  <h3 className="h6">{dateString}</h3>
                </li>
                <li>
                  <RiTimeLine title="time" />
                  <h3 className="h6">{timeString}</h3>
                </li>
              </ul>
            </div>
            <div className="event-desc text-columns">
              <p>
                If you are discerning studying theology at the graduate level,
                join us for a virtual summit and learn how the Augustine
                Institute heeds St. Paul’s exhortation to the Romans: “Do not be
                conformed to this world but be transformed by the renewal of
                your mind” (Rom. 12:2).
              </p>
              <p>
                The summit will include discussions on the principles of
                theological study led by professors of the Graduate School,
                followed by breakout sessions where attendees will have the
                opportunity to interact more closely with a faculty member and
                dive deeper into a particular topic typical to one of our
                classes.
              </p>
            </div>
            <div className="hide-xs show-md">
              <Bonus />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-5">
            <div className="registration">
              <div className="registration__inner">
                <div className="registration__dots hide-xs show-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 90 230"
                  >
                    <circle cx="25" cy="25" r="5" fill="#fff" />
                    <circle cx="25" cy="145" r="5" fill="#fff" />
                    <circle cx="65" cy="145" r="5" fill="#fff" />
                    <circle cx="45" cy="25" r="5" fill="#fff" />
                    <circle cx="45" cy="145" r="5" fill="#fff" />
                    <circle cx="85" cy="145" r="5" fill="#fff" />
                    <circle cx="25" cy="45" r="5" fill="#fff" />
                    <circle cx="25" cy="165" r="5" fill="#fff" />
                    <circle cx="65" cy="165" r="5" fill="#fff" />
                    <circle cx="45" cy="45" r="5" fill="#fff" />
                    <circle cx="45" cy="165" r="5" fill="#fff" />
                    <circle cx="85" cy="165" r="5" fill="#fff" />
                    <circle cx="25" cy="65" r="5" fill="#fff" />
                    <circle cx="25" cy="185" r="5" fill="#fff" />
                    <circle cx="65" cy="185" r="5" fill="#fff" />
                    <circle cx="45" cy="65" r="5" fill="#fff" />
                    <circle cx="45" cy="185" r="5" fill="#fff" />
                    <circle cx="85" cy="185" r="5" fill="#fff" />
                    <circle cx="25" cy="85" r="5" fill="#fff" />
                    <circle cx="25" cy="205" r="5" fill="#fff" />
                    <circle cx="65" cy="85" r="5" fill="#fff" />
                    <circle cx="65" cy="205" r="5" fill="#fff" />
                    <circle cx="45" cy="85" r="5" fill="#fff" />
                    <circle cx="45" cy="205" r="5" fill="#fff" />
                    <circle cx="85" cy="85" r="5" fill="#fff" />
                    <circle cx="85" cy="205" r="5" fill="#fff" />
                    <circle cx="25" cy="105" r="5" fill="#fff" />
                    <circle cx="25" cy="225" r="5" fill="#fff" />
                    <circle cx="65" cy="105" r="5" fill="#fff" />
                    <circle cx="65" cy="225" r="5" fill="#fff" />
                    <circle cx="45" cy="105" r="5" fill="#fff" />
                    <circle cx="45" cy="225" r="5" fill="#fff" />
                    <circle cx="85" cy="105" r="5" fill="#fff" />
                    <circle cx="85" cy="225" r="5" fill="#fff" />
                    <circle cx="25" cy="125" r="5" fill="#fff" />
                    <circle cx="65" cy="125" r="5" fill="#fff" />
                    <circle cx="45" cy="125" r="5" fill="#fff" />
                    <circle cx="85" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="5" r="5" fill="#fff" />
                    <circle cx="5" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="25" r="5" fill="#fff" />
                    <circle cx="5" cy="145" r="5" fill="#fff" />
                    <circle cx="5" cy="45" r="5" fill="#fff" />
                    <circle cx="5" cy="165" r="5" fill="#fff" />
                    <circle cx="5" cy="65" r="5" fill="#fff" />
                    <circle cx="5" cy="185" r="5" fill="#fff" />
                    <circle cx="5" cy="85" r="5" fill="#fff" />
                    <circle cx="5" cy="205" r="5" fill="#fff" />
                    <circle cx="5" cy="105" r="5" fill="#fff" />
                  </svg>
                </div>
                {(daysToEvent > 0 && (
                  <>
                    <div className="registration__title">
                      <h2 className="h5" style={{ marginBottom: '0.25em' }}>
                        Event begins in
                      </h2>
                      <h3 className="h2">{daysToEventString}</h3>
                    </div>
                    <div className="registration__form">
                      <Form />
                    </div>
                  </>
                )) || (
                  <div className="registration__title">
                    <h3 className="h2">Event has ended</h3>
                    <h2 className="h5">Thank you for your participation!</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 hide-md">
            <Bonus />
          </div>
        </div>
      </section>
      <section className="container section-m-b">
        <Tabs id="courses">
          <TabList>
            <Tab id="event-schedule">
              <span className="h3">Event Schedule</span>
            </Tab>
            <Tab id="course-of-study2">
              <span className="h3">Speakers</span>
            </Tab>
          </TabList>
          <TabPanelWrapper>
            <TabPanel>
              <p className="narrow">
                This livestream event will start at{' '}
                <strong>
                  {startTimeString} on {dateString}
                </strong>
                . A weblink will be provided after registration to view the
                following program online:
              </p>
              <Schedule schedule={schedule} />
              <div className="breakout-sessions">
                <Sessions sessions={breakoutSessions} />
              </div>
            </TabPanel>
            <TabPanel>
              <Speakers speakers={speakers} />
            </TabPanel>
          </TabPanelWrapper>
        </Tabs>
      </section>
    </main>
  );
}

export function Head({ location: { pathname } }: HeadProps) {
  return (
    <SEO
      title="Virtual Summit - Theology: The Renewal of the Mind"
      description="If you are discerning studying theology at the graduate level, join us for a virtual summit. The summit will include discussions on the principles of theological study led by professors of the Graduate School."
      pathname={pathname}
      image="/2021/social-card.jpg"
    />
  );
}
