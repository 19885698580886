import { StaticImage } from 'gatsby-plugin-image';

export const speakers = [
  {
    name: 'Dr. Sean Innerst',
    title: 'Academic Dean',
    image: (
      <StaticImage
        src="../../images/speakers/sean-innerst.jpg"
        alt="Dr. Sean Innerst"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Sean Innerst is the Academic Dean of the Augustine Institute Graduate
        School and has been teaching theology, evangelization, and catechesis to
        seminarians, college students, missionaries, graduate students, DREs,
        deacon candidates, and catechists for over twenty-five years. He helped
        found two important Catholic institutions in Denver: St. John Vianney
        Theological Seminary, as its founding provost, and the Augustine
        Institute, as its founding academic dean. He earned his doctorate in
        theology from the University of South Africa.
      </p>
    ),
  },
  {
    name: 'Dr. Michael Barber',
    title: 'Professor of Scripture & Theology',
    image: (
      <StaticImage
        src="../../images/speakers/michael-barber.jpg"
        alt="Dr. Michael Barber"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Barber is Professor of Theology and Scripture at the Augustine
        Institute Graduate School. His most recent academic publication is Paul,
        <em>A New Covenant Jew: Rethinking Pauline Theology</em> (Grand Rapids:
        Eerdmans, 2019), co-written with Brant Pitre and John Kincaid. In
        addition to his teaching, academic research and publishing, he also
        gives popular-level presentations at Catholic conferences and parish
        events around the United States. He earned his PhD in Theology from
        Fuller Theological Seminary.
      </p>
    ),
  },
  {
    name: 'Dr. Elizabeth Klein',
    title: 'Assistant Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/elizabeth-klein.jpg"
        alt="Dr. Elizabeth Klein"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Klein is an Assistant Professor of Theology at the Augustine
        Institute Graduate School. She specializes in patristic theology,
        particularly the writings of St. Augustine. She completed her PhD from
        the University of Notre Dame in 2016, and after holding a postdoctoral
        position at Notre Dame, she came to teach at the Augustine Institute in
        2017. She is the author of <em>Augustine’s Theology of Angels</em>{' '}
        (Cambridge University Press, 2018) and{' '}
        <em>God: What Every Catholic Should Know</em> (Ignatius Press; Augustine
        Institute, 2019).
      </p>
    ),
  },
  {
    name: 'Dr. Scott Hefelfinger',
    title: 'Assistant Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/scott-hefelfinger.jpg"
        alt="Dr. Scott Hefelfinger"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Hefelfinger serves as Assistant Professor of Theology in the
        Augustine Institute Graduate School. He teaches in the areas of moral
        and dogmatic theology, specializing in Catholic social thought, marriage
        and family, and the theology of the interior life. His current research
        includes a manuscript on the common good and international society, an
        article on marital friendship, and a primer on Catholic social teaching.
        Dr. Hefelfinger earned his PhD in Moral Theology at the University of
        Notre Dame.
      </p>
    ),
  },
  {
    name: 'Dr. Bronwen McShea',
    title: 'Visiting Assistant Professor of History',
    image: (
      <StaticImage
        src="../../images/speakers/bronwen-mcshea.jpg"
        alt="Dr. Bronwen McShea"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. McShea is a Visiting Assistant Professor of History at the Augustine
        Institute Graduate School. A writing fellow at the Institute on Religion
        and Public Life in New York City, she is a historian with research
        interests in the early modern Church, especially in France and in
        colonial and missionary contexts. Dr. McShea is the author of{' '}
        <em>Apostles of Empire: The Jesuits and New France</em> (University of
        Nebraska Press, 2019). She completed her PhD in Early Modern European
        History at Yale University.
      </p>
    ),
  },
  {
    name: 'Dr. Mark Giszczak ',
    title: 'Associate Professor of Sacred Scripture',
    image: (
      <StaticImage
        src="../../images/speakers/mark-giszczak.jpg"
        alt="Dr. Mark Giszczak "
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Giszczak is an Associate Professor of Sacred Scripture at the
        Augustine Institute Graduate School. He specializes in Old Testament
        Wisdom Literature as well as biblical theology and Catholic biblical
        interpretation. Dr. Giszczak has a passion for Scripture and loves
        helping Catholics read, pray, and understand the Bible. His forthcoming
        publications include a commentary on Wisdom of Solomon in the Catholic
        Commentary on Sacred Scripture Series. He earned his PhD in Biblical
        Studies from the Catholic University of America.
      </p>
    ),
  },
  {
    name: 'Professor Lucas Pollice ',
    title:
      'Associate Professor of Theology and Catechetics | Director, MA in Leadership for the New Evangelization Degree Program',
    image: (
      <StaticImage
        src="../../images/speakers/lucas-pollice.jpg"
        alt="Professor Lucas Pollice "
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Professor Pollice is an Associate Professor of Theology and Catechetics
        and Director of the MA in Leadership for the New Evangelization Degree
        Program. He has over twenty years of extensive leadership, ministry, and
        teaching experience in the areas of catechetics and pastoral theology at
        both the parish and diocesan levels. Professor Pollice was awarded the
        Benemerenti Medal by Pope Benedict XVI for outstanding service to the
        Church in 2011. He completed his Master’s in Theological Studies from
        St. Mary’s College of Ave Maria University.
      </p>
    ),
  },
];
