import { StaticImage } from 'gatsby-plugin-image';

// ! Note: If you edit this, you'll need to edit /functions/ics.js too!
export const breakoutSessions = [
  {
    title:
      'St. Therese of Lisieux in Post-Revolutionary France, Dr. Bronwen McShea',
    image: (
      <StaticImage
        src="../../images/sessions/bronwen-mcshea-2.jpg"
        alt="Bronwen McShea"
        layout="fullWidth"
      />
    ),
    desc: `In this talk, students will learn about the remarkable life and writings of the youngest person, and one of the first women, to be declared a Doctor of the Church. At the same time, Thérèse's story will be presented in the light of broader historical developments in 19th-century Europe. These included the reinvigoration of the Catholic Church, and in particular women's religious communities, in France, on a scale that surprised many given the revolutionary violence of the late 18th century that threatened to extinguish congregations such as the Discalced Carmelites, the order Thérèse joined.`,
    zoom: {
      title: 'St. Therese of Lisieux in Post-Revolutionary France',
      link: 'https://augustineinstitute-org.zoom.us/j/91425351671',
      id: '914 2535 1671',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/ahKX69piO',
    },
  },
  {
    title: 'The Cosmic Temple in the Old Testament, Dr. Mark Giszczak',
    image: (
      <StaticImage
        src="../../images/sessions/mark-giszczak-2.jpg"
        alt="Dr. Mark Giszczak"
        layout="fullWidth"
      />
    ),
    desc: `Have you ever wondered why the high priest’s robe is blue? Or why St. Paul compares the resurrected souls to stars? The ancient Jews conceived of the whole universe not just as a system, but a sanctuary. In this session, we will explore how the Bible portrays the cosmos as a temple and the temple as a cosmos, revealing the liturgical structure of reality and our place within it.`,
    zoom: {
      title: 'The Cosmic Temple in the Old Testament',
      link: 'https://augustineinstitute-org.zoom.us/j/95125204091',
      id: '951 2520 4091',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/abrUQSzdpK',
    },
  },
  {
    title: 'The New Evangelization: Renewal and Mission, Prof. Lucas Pollice',
    image: (
      <StaticImage
        src="../../images/sessions/lucas-pollice-2.jpg"
        alt="Prof. Lucas Pollice"
        layout="fullWidth"
      />
    ),
    desc: `This breakout will explore the vision of authentic ecclesial renewal and mission that is at the heart of the New Evangelization. We’ll discover how this renewal and mission has its roots in the pastoral vision of Vatican II and how the laity are called in an especially unique way to carry out the New Evangelization in the Church today.`,
    zoom: {
      title: 'The New Evangelization: Renewal and Mission',
      link: 'https://augustineinstitute-org.zoom.us/j/91281523500',
      id: '912 8152 3500',
      localNumber: 'https://augustineinstitute-org.zoom.us/u/acXRPbxsjt',
    },
  },
];
