import { StaticImage } from 'gatsby-plugin-image';
import { BiLinkExternal } from 'react-icons/bi';

export default function Bonus() {
  return (
    <div className="event-bonus">
      <div className="event-bonus__text">
        <div className="event-bonus__text__box">
          <h3 className="h6">
            All attendees will be entered into a raffle to win a book by one of
            our faculty members:
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 35 35"
              width="35"
              height="35"
            >
              <circle cx="32.5" cy="32.5" r="2.5" />
              <circle cx="32.5" cy="22.5" r="2.5" />
              <circle cx="32.5" cy="12.5" r="2.5" />
              <circle cx="32.5" cy="2.5" r="2.5" />
              <circle cx="22.5" cy="32.5" r="2.5" />
              <circle cx="22.5" cy="22.5" r="2.5" />
              <circle cx="12.5" cy="22.5" r="2.5" />
              <circle cx="12.5" cy="32.5" r="2.5" />
              <circle cx="2.5" cy="32.5" r="2.5" />
            </svg>
          </h3>
        </div>
        <div className="event-bonus__text__books">
          <ul>
            <li>
              <a
                href="https://catholic.market/authors/dr-brant-pitre/the-case-for-jesus-book/"
                className="event-bonus__text__books__book"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="event-bonus__text__books__book__img">
                  <StaticImage
                    src="../../images/books/case-for-jesus.jpg"
                    alt=""
                  />
                </div>
                <p className="event-bonus__text__books__book__text">
                  The Case for Jesus: The Biblical and Historical Evidence for
                  Christ, Dr. Brant Pitre
                  <BiLinkExternal />
                </p>
              </a>
            </li>
            <li>
              <a
                href="https://catholic.market/books/salvation-what-every-catholic-should-know/"
                className="event-bonus__text__books__book"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="event-bonus__text__books__book__img">
                  <StaticImage src="../../images/books/salvation.jpg" alt="" />
                </div>
                <p className="event-bonus__text__books__book__text">
                  Salvation: What Every Catholic Should Know, Dr. Michael Barber
                  <BiLinkExternal />
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div className="event-bonus__text__discount">
          <h3 className="h6">
            We are waiving the graduate school application fee for anyone who
            attends this event!
          </h3>
        </div>
      </div>
      <div className="event-bonus__img hide-xs show-sm">
        <div className="event-bonus__img__inner">
          <StaticImage
            src="../../images/2021/home-img.jpg"
            alt=""
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  );
}
